import React from 'react';
const Clock = () => (
	<svg
		width="19px"
		height="21px"
		viewBox="0 0 19 21"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<desc>Created with Lunacy</desc>
		<g id="icon/clock">
			<g id="Group-9">
				<path
					d="M15.6077 5.52183L15.4276 5.70192C16.2684 6.96251 16.749 8.52366 16.8087 10.2052C16.8087 14.8277 13.0269 18.6095 8.40434 18.6095C3.78178 18.6095 0 14.8277 0 10.2052C0 6.18253 2.82171 2.82171 6.60349 1.98093L6.60349 0L10.2052 0L10.2052 2.04058C11.1653 2.28031 12.0657 2.64047 12.9065 3.18073L13.0865 3.00064C13.7472 2.28031 14.8874 2.33995 15.6077 3.00064C16.3281 3.66134 16.3281 4.80149 15.6077 5.52183ZM2.34225 10.2052C2.34225 13.5064 5.04353 16.2076 8.34469 16.2076C11.6459 16.2076 14.3471 13.5064 14.3471 10.2052C14.3471 6.90402 11.6459 4.20275 8.34469 4.20275C5.04353 4.20275 2.34225 6.90402 2.34225 10.2052Z"
					id="Fill-1"
					fill="#768B8E"
					fillRule="evenodd"
					stroke="none"
				/>
				<path
					d="M4.38168 3.12111L1.80085 1.55999L1.80085 0.900424C1.80085 0.419814 1.38103 0 0.900424 0C0.419814 0 0 0.419814 0 0.900424L0 2.58083L3.42161 4.62141C3.54204 4.68106 3.72214 4.74184 3.90222 4.74184C4.20275 4.74184 4.50212 4.56175 4.6822 4.32202C4.92194 3.96186 4.80264 3.36083 4.38168 3.12108L4.38168 3.12111Z"
					transform="translate(7.444271 8.103785)"
					id="Fill-2"
					fill="#768B8E"
					stroke="none"
				/>
				<path
					d="M1.19983 0.600179C1.19983 1.40042 0 1.40042 0 0.600179C0 -0.20006 1.19983 -0.20006 1.19983 0.600179"
					transform="translate(7.744768 4.802363)"
					id="Fill-3"
					fill="#768B8E"
					stroke="none"
				/>
				<path
					d="M1.20095 0.600179C1.20095 1.40042 0 1.40042 0 0.600179C0 -0.20006 1.20095 -0.20006 1.20095 0.600179"
					transform="translate(2.942153 9.603855)"
					id="Fill-4"
					fill="#768B8E"
					stroke="none"
				/>
				<path
					d="M1.19983 0.600179C1.19983 1.40042 0 1.40042 0 0.600179C0 -0.20006 1.19983 -0.20006 1.19983 0.600179"
					transform="translate(7.744768 14.4065)"
					id="Fill-5"
					fill="#768B8E"
					stroke="none"
				/>
				<path
					d="M1.20095 0.600179C1.20095 1.40042 0 1.40042 0 0.600179C0 -0.20006 1.20095 -0.20006 1.20095 0.600179"
					transform="translate(12.54629 9.603855)"
					id="Fill-6"
					fill="#768B8E"
					stroke="none"
				/>
			</g>
		</g>
	</svg>
);

export { Clock };
